/* You can add global styles to this file, and also import other style files */
@import "@aposin/ng-aquila/css/utilities.css";
@import "@aposin/ng-aquila/styles/theming";

@import "@angular/cdk/overlay-prebuilt.css";
@import "@angular/cdk/a11y-prebuilt.css";

/*-----------------------------------------------------------------------------
    $ Common
-----------------------------------------------------------------------------*/

nx-badge{
  width: 100px;
  height: 40px;
}

.input_case{
  .c-input{
      text-transform: uppercase;
  }
}

.text_no_wrap {
  white-space: nowrap;
}

.color_disabled {
  color: #808080 !important;
}

.color_dark_blue {
  color: #006192;
}

::ng-deep .nx-margin-bottom-m[style] {
  margin-bottom: 30px !important;
}

.form_row_inline{
  display: grid !important;
}

.table_column_issuance{
  width: 180px;
  display: block;
}

.table_vertical_top{
  .nx-table-cell{
    vertical-align: top;
  }
}

/*-----------------------------------------------------------------------------
    $ Formfield font size
-----------------------------------------------------------------------------*/
// .nx-formfield__flexfield{
//   font-weight: 400 !important;
// }

/*-----------------------------------------------------------------------------
    $ Tabs
-----------------------------------------------------------------------------*/

// .nx-tab-header__item{
//   .nx-indicator--over-text{
//     display: none;
//   }
// }

// .nx-tab-header__item--active{
//   .nx-indicator--over-text{
//     display: unset;
//   }
// }

// .tab-header-content {
//   position: relative;
//   display: inline-block;
// }

/*-----------------------------------------------------------------------------
    $ Pagination
-----------------------------------------------------------------------------*/

.pagination_position{
  float: right;

  @media (max-width: 703px) {
    float: none;
    display: table !important;
    margin: 0 auto;
  }

  .nx-pagination__item {
    margin: 0 10px !important;
  }

  .nx-pagination__item:last-child {
    margin: 0 !important;
  }

  .nx-pagination__item:first-child {
    margin: 0 !important;
  }
}

.pagination_form{
  .nx-natural-language-form__wrapper{
    align-items: center;
  }

  &.nx-natural-language-form--small{
    font-size: var(--formfield-control-font-size) !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  &.nx-natural-language-form__wrapper > * {
    height: var(--formfield-control-height) !important;
    min-height: var(--formfield-control-height) !important;
  }

  .size-regular{
    min-width: 74px !important;
  }

  &.nx-grid--no-padding.nx-grid--media-query .nx-grid__row {
    align-items: center!important;
  }

  nx-dropdown .nx-dropdown__container {
    font-size: 16px !important;
    font-weight: 600;
  }

  nx-dropdown.is-filled, nx-dropdown.has-focus {
    color: #414141 !important;
    border-color: #414141 !important;
  }
}



/*-----------------------------------------------------------------------------
    $ PopUp
-----------------------------------------------------------------------------*/

// .nx-modal-container, .cdk-overlay-pane{
// .cdk-overlay-pane{
//   @media (max-width: 703px) {
//     width: 350px !important;
//   }
// }


// .upload_content{
//   &.nx-list--xsmall li{
//     padding-left: 24px !important;
//   }

//   // &.nx-list--small li{
//   //   margin-left: 30px !important;
//   // }

//   &.nx-list--xsmall li:before, &.nx-list--small li:before{
//     font-weight: 400 !important;
//     width: auto !important;
//     content: counter(list) "." !important;
//   }
// }

.nofiles_title{
  font-weight: 300;
  padding-bottom: 20px;
  font-size: 30px;

  @media (max-width: 704px) {
    font-size: 26px;
  }
}

.nofiles_button{
  margin-bottom: 0px;

  @media (max-width: 704px) {
    margin-bottom: 20px;
  }
}

nx-modal-container {
  margin: 0 !important;
  padding: 40px !important;
}

.hasupload{
  padding: 40px !important;
}

.hasupload_btn_gap{
    padding-bottom: 42px;

    @media (max-width: 704px) {
    padding-bottom: 20px;
    padding-left: 10px;
    }
}

.upload_title {
  font-weight: 400;
  padding-bottom: 20px;
  font-size: 2em;
}

.delete_title {
  font-weight: 300;
  padding-bottom: 20px;
  font-size: 30px;

  @media (min-width: 704px) {
    font-size: 24px;
  }

  @media (min-width: 992px) {
    font-size: 30px;
    padding-bottom: 30px;
  }
}

.upload_error_center {
  text-align-last: center;
  text-align: -webkit-center;
  margin-top: 16px;

  @media (min-width: 704px) {
    margin-top: 11px;
  }
}

.button_upload_error {
  min-width: 35%;

  @media (max-width: 704px) {
    max-width: 100%;
  }
}

.upload_des {
  font-size: 1.17em;
  font-weight: 300;
  line-height: 1.2;
  padding-bottom: 20px;
}

.upload_doc_row {
  overflow-x: hidden !important;
  max-height: calc(70vh - 200px) !important;
  margin: 0 !important;
  padding: 0 16px 0 0 !important;

  &::-webkit-scrollbar-thumb:hover {
    background: #a8a8a8 !important;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(83, 83, 83, 0.07);
    background-color: rgb(212, 212, 212);
  }

  &::-webkit-scrollbar {
    width: 8px;
    background-color: rgb(212, 212, 212);
  }

  &::-webkit-scrollbar-thumb {
    background-color: #007ab3;
  }
}

.upload_list_button {
  display: flex;
  white-space: nowrap;

  @media (max-width: 1024px) {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 10px;
  }
}

.tablet_display {
  display: flex;

  @media (max-width: 1024px) {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    row-gap: 10px;
    white-space: nowrap;
  }

  @media (max-width: 703px) {
    display: inline-flex;
    row-gap: 10px;
    flex-direction: column;
    align-items: flex-start;
  }
}

.button_upload_2btn {
  margin-right: 10px;

  @media (max-width: 703px) {
    margin-bottom: 15px;
    margin-right: 0;
  }
}

.button_delete_2btn {
  margin-right: 10px;

  @media (max-width: 703px) {
    margin-bottom: 25px;
    margin-right: 0;
  }
}

.upload_table {
  border-top: 1px solid #d9d9d9;

  .nx-header-cell,
  .nx-table-cell {
    padding-left: 8px !important;
    padding-right: 8px !important;
    font-size: 14px;
    line-height: 1.2;
  }

  .nx-header-cell:first-child,
  .nx-table-cell:first-child {
    width: 20%;
    white-space: pre-wrap;
  }

  .nx-header-cell:nth-child(2),
  .nx-table-cell:nth-child(2) {
    width: 10%;
    white-space: pre-wrap;
  }

  .nx-header-cell:nth-child(3),
  .nx-table-cell:nth-child(3) {
    width: 20%;
    white-space: pre-wrap;
  }

  .nx-header-cell:last-child,
  .nx-table-cell:last-child {
    width: 50%;
  }
}

.upload_view_btn_space {
  margin-right: 10px;
}

.upload_view {
  font-size: 14px !important;
  font-weight: 400 !important;
}

.upload_content {

  // list-style-position: inside; /* Optional: Moves the marker inside the padding area */
  padding-left: 16px;
  /* Adjust as needed */

}

// .upload_content li {
//   padding-left: 14px; /* Adjusts space between marker and text */
// }

.upload_content_bold{
  &.nx-list--xsmall li{
    padding-left: 24px !important;
  }

  // &.nx-list--small li{
  //   margin-left: 30px !important;
  // }

  &.nx-list--xsmall li:before, &.nx-list--small li:before{
    font-weight: 700 !important;
    width: auto !important;
    content: counter(list) "." !important;
  }
}

.upload_btn_width{
  width: 130px !important;
}

.upload_dropdown {
  &.nx-formfield {
    width: auto;
  }

  .nx-dropdown__rendered {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    white-space: pre-wrap !important;
  }

  .nx-formfield__wrapper {
    padding-bottom: 10px !important;
    padding-top: 20px !important;
  }

}
//popup blue title

.popup_blue_title {
  color: #0079b3 !important;
}

/*-----------------------------------------------------------------------------
    $ Badge
-----------------------------------------------------------------------------*/

nx-badge {
  font-size: 12px !important;
  line-height: 1.2 !important;
  padding: 6px 12px !important;
  font-weight: 600 !important;
  border-radius: 40px !important;
  // height: 40px !important;
  letter-spacing: 1px !important;
  width: 100px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-align-last: center;
}

.saved_badge {
  &.nx-badge--active {
    background-color: #d9d9d9 !important;
  }
}

.printed_badge {
  &.nx-badge--active {
    background-color: #cfe9ee !important;
  }
}

.approved_badge {
  &.nx-badge--active {
    background-color: #e3ebaf !important;
  }
}

.pending_badge {
  &.nx-badge--active {
    background-color: #e1cfeb !important;
    line-height: 18px;
    white-space: pre-wrap;
  }
}

.cancel_badge {
  &.nx-badge--active {
    background-color: #f7c7c3 !important;
  }
}

.pending_cancel_badge {
  &.nx-badge--active {
    background-color: #ffe8b0 !important;
    line-height: 18px;
    white-space: pre-wrap;
  }
}

.submitted_badge {
  &.nx-badge--active {
    background-color: #e1cfeb !important;
  }
}

.revised_badge {
  &.nx-badge--active {
    background-color: #cfe9ee !important;
  }
}

.active_badge {
  &.nx-badge--active {
    background-color: #c3dcbb !important;
  }
}

.expired_badge {
  &.nx-badge--active {
    background-color: #f7c7c3 !important;
  }
}

.converted_badge {
  &.nx-badge--active {
    background-color: #cfe9ee !important;
  }
}

.pending_abs_badge {
  &.nx-badge--active {
    background-color: #e1cfeb !important;
  }
}



/*-----------------------------------------------------------------------------
    $ Enquiry
-----------------------------------------------------------------------------*/

.enquiry_status {
  .nx-grid__column--media-query.nx-grid__column-small-12 {
    @media (max-width: 703px) {
      margin-bottom: 20px !important;
    }
  }

  &.nx-grid__column--media-query.nx-grid__column-2xlarge-2 {
    @media (min-width: 1280px) {
      text-align-last: end;
      text-align: -webkit-right;
      margin-bottom: 0;
    }
  }

  &.nx-grid__row--media-query {
    @media (min-width: 1472px) {
      align-items: center;
      margin-bottom: 0;
    }
  }
}

/*-----------------------------------------------------------------------------
    $ Completed
-----------------------------------------------------------------------------*/

.completed_status {
  &.nx-grid__column--media-query.nx-grid__column-small-12 {
    @media (min-width: 320px) {
      margin-bottom: 20px;
    }

    @media (min-width: 704px) {
      margin-bottom: 0;
    }
  }

  &.nx-grid--no-padding.nx-grid--media-query .nx-grid__row {
    @media (max-width: 703px) {
      flex-direction: column-reverse !important;
    }
  }

  &.nx-grid__column--media-query.nx-grid__column-2xlarge-2 {
    @media (min-width: 1472px) {
      text-align-last: end;
      text-align: -webkit-right;
      margin-bottom: 0;
    }

    @media (min-width: 1280px) {
      text-align-last: end;
      text-align: -webkit-right;
      margin-bottom: 0 !important;
    }
  }

  &.nx-grid__row--media-query {
    @media (min-width: 1472px) {
      align-items: center;
      margin-bottom: 0 !important;
    }
  }

  &.nx-grid__row.nx-grid__row--media-query{
    align-items: center;
  }

  .nx-grid__column--media-query.nx-grid__column-medium-2 {
    @media (min-width: 704px) {
      flex: 0 0 20% !important;
      max-width: 20% !important;
    }
  }

  .nx-grid__column--media-query.nx-grid__column-medium-10{
    @media (min-width: 704px) {
      flex: 0 0 80% !important;
      max-width: 80% !important;
    }
  }
}

.completed_title {
  &.nx-heading--new-api.nx-heading--s {
    @media (max-width: 703px) {
      font-size: 24px !important;
    }
  }
}

.completed_color_primary {
  color: var(--interactive-primary) !important;
}

.completed_card {
  .nx-card-inner {
    padding: 23px 23px 0 23px !important;
  }
}

.card_title{
  font-size: 24px;
  font-weight: 400;

  @media (max-width: 704px) {
    font-weight: 600;
  }
}

.product_name{
  font-size: 24px;
  font-weight: 700;
}

.completed_list {
  &.nx-list--small li {
    margin-bottom: 2px !important;
    padding: 0 0 0 18px !important;
  }

  ::before {
    height: 4px !important;
    width: 4px !important;
    margin-top: 9px !important;
    margin-left: -14px !important;
  }
}

/*-----------------------------------------------------------------------------
    $ Notification
-----------------------------------------------------------------------------*/

.green_color_notification {
  &.context-info {
    border-color: var(--message-success-border-color) !important;
    background-color: var(--message-success-background-color) !important;
  }

  &.context-info .nx-message__icon {
    color: var(--message-success-icon-color) !important;
  }
}

/*-----------------------------------------------------------------------------
    $ Form field
-----------------------------------------------------------------------------*/

@media (max-width: 703px) {
  .nx-formfield__wrapper {
    padding-bottom: var(--formfield-bottom-padding) !important;
  }
}

.nx-checkbox__input:disabled + .nx-checkbox__label {
  color: #808080 !important;
  border-color: #808080 !important;
}

input:disabled{ opacity:1;}

// .nx-checkbox__control {
//   color: #808080 !important;
//   border-color: #808080 !important;
// }

.nx-formfield.is-disabled {
  color: #808080 !important;
  -webkit-text-fill-color: #808080 !important;
}

.nx-formfield.is-disabled .nx-formfield__input-container {
  border-bottom-color: #808080 !important;
}

.nx-checkbox__input:checked:disabled
  + .nx-checkbox__label
  .nx-checkbox__control {
  background-color: #808080 !important;
}

.nx-checkbox__input:disabled + .nx-checkbox__label .nx-checkbox__control {
  border-color: #808080 !important;
}

/*-----------------------------------------------------------------------------
    $ Flex
-----------------------------------------------------------------------------*/

.horizontal-checkboxes {
  display: flex;
  flex-direction: row;
}

/*-----------------------------------------------------------------------------
    $ Card
-----------------------------------------------------------------------------*/

.card_summary {
  width: 100%;
  box-shadow: 0 0 10px -5px #000 !important;
  border-color: #ececec !important;
  padding: 16px;

  @media (max-width: 703px) {
    box-shadow: 0px 0px 0px 0px !important;
    border-color: transparent !important;
    padding: 0px;
    .nx-card-inner {
      padding: 0 !important;
    }
  }
}

/*-----------------------------------------------------------------------------
    $ Button
-----------------------------------------------------------------------------*/

.button_black{
  color: #414141 !important;
}

.nx-button--secondary{
  background-color: #ffffff !important;
}

.nx-button--secondary:active, .nx-button--secondary.nx-button--active{
  background-color: var(--button-secondary-active-background-color) !important;
}

.nx-button--secondary:hover:not([disabled]):not(:active) {
  background-color: var(--button-secondary-hover-background-color) !important;
}

::ng-deep.nx-plain-button__content-wrapper {
  display: inline-flex;
  align-items: start !important;
  justify-content: center;
  vertical-align: bottom;
}

.btn_small_full_width{
  @media (max-width: 704px) {
      width: 100% !important;
  }
}

.button-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;

  @media (max-width: 704px) {
    display: inline-grid !important;
  }

  button.nx-button {
    padding-left: 18px;
    padding-right: 18px;
  }

}

.clause_button {
  display: flex;
  gap:20px;
  .btn{
    flex: 1 1 100%;
  }

  @media (max-width: 704px) {
    display: grid;
  }
}

// Dashboard //
.button_dashboard {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: right;

  button {
    margin: 0 20px 20px 0;

    @media (max-width: 703px) {
      margin: 0 0 20px 0 ;
    }
  }
}

// ISM //
.button_ism {
  display: flex;
  flex-wrap: wrap;

  button {
    margin: 0 20px 20px 0;

    @media (max-width: 703px) {
      margin: 0 0 20px 0 ;
    }
  }
}

// Display //

.button_area_bg {
  background-color: #f2f2f2;
  padding: 30px 0;
}
// Popup //
.button_popup_2btn {
  margin-right: 20px;

  @media (max-width: 703px) {
    margin-bottom: 20px;
    margin-right: 0;
  }
}

// Completed //
.button_completed {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 20px;
  margin-top: 20px;

  button {
    margin: 0 20px 20px 0;

    @media (max-width: 703px) {
      margin: 0 0 20px 0;
      width: 100% !important;
    }
  }
}

* {
  font-family: "Allianz Neo", sans-serif !important;
}

.font-grey {
  color: #8d8d8d;
}

hr {
  border: 0;
  border-top: 1px solid #d9d9d9;
  padding-top: 0;
  margin: 0 !important;
}

.extra_coverage_text {
  text-align: right;

  @media (max-width: 703px) {
    text-align: left;
  }
}

/*-----------------------------------------------------------------------------
    $ Margin and Padding
-----------------------------------------------------------------------------*/

.nx-margin-bottom-1xs {
  margin-bottom: 2px !important;
}

.nx-margin-top-50 {
  margin-top: 50px !important;
}

.padding_0 {
  padding: 0 !important;
}

.padding_left_10 {
  padding-left: 10px !important;
}

.padding_left_20 {
  padding-left: 20px !important;
}

.padding_right_10 {
  padding-right: 10px !important;
}

.padding_left_20 {
  padding-left: 20px !important;
}

.padding_left_36 {
  padding-left: 36px;
}

.padding_left_53 {
  padding-left: 53px;
}

.padding_top_20 {
  padding-top: 20px !important;
}

@media (max-width: 704px) {
  .m_margin_top_10 {
    margin-top: 10px;
  }

  .m_margin_top_20 {
    margin-top: 20px;
  }

  .m_padding_top_10 {
    padding-top: 10px !important;
  }

  .m_padding_bottom_10 {
    padding-bottom: 10px !important;
  }

  .m_padding_top_16 {
    padding-top: 16px !important;
  }

  .m_padding_top_20 {
    padding-top: 20px !important;
  }

  .m_padding_bottom_20 {
    padding-bottom: 20px !important;
  }

  .m_padding_left_20 {
    padding-left: 20px !important;
  }

  .m_padding_right_20 {
    padding-right: 20px !important;
  }
}

/*-----------------------------------------------------------------------------
    $ Grid
-----------------------------------------------------------------------------*/

.column_cp_content {
  &::before {
    padding-right: 6px;
    content: ":";
    float: left;
    font-size: 16px;
  }
  text-indent: -6px;
  word-break: break-word;
}

.column_form_content {
  &::before {
    padding-right: 6px;
    margin-left: 8px;
    content: ":";
    float: left;
    font-size: 16px;
  }
  text-indent: -3px;
}

.column_cp_content_bold {
  &::before {
    padding-right: 6px;
    content: ":";
    float: left;
    font-size: 16px;
    font-weight: bold;
  }
  text-indent: -6px;
}

.grid_remove_padding {
  &.nx-grid.nx-grid--media-query {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
}

.grid_width_page {
  &.nx-grid__column--media-query.nx-grid__column-xlarge-3 {
    @media (min-width: 992px) and (max-width: 1265px) {
      flex: 0 0 23% !important;
      max-width: 23% !important;
    }

    @media (min-width: 1266px)  {
      flex: 0 0 21% !important;
      max-width: 21% !important;
    }
  }

  &.nx-grid__column--media-query.nx-grid__column-xlarge-9 {
    @media (min-width: 992px) and (max-width: 1265px) {
      flex: 0 0 77% !important;
      max-width: 77% !important;
    }

    @media (min-width: 1266px) {
      flex: 0 0 79% !important;
      max-width: 79% !important;
    }
  }
}

//==== Summary ====//

.data_display_font {
  word-break: break-word;
}

.summary_table{
  border-bottom: 0 !important;
  border-top: 0  !important;

  .nx-table-cell{
    padding: 0 !important;
    border-top: 0  !important;
    vertical-align: top;
  }

  &.nx-header-cell:last-child, .nx-table-cell:last-child{
    padding-right: 0 !important;
  }

  &.nx-header-cell:first-child, .nx-table-cell:first-child{
    width: 280px;
    padding-left: 0 !important;

    @media (max-width: 703px) {
      width: 50%;
    }
  }
}

.cp_not_complete {
  border-bottom: 0 !important;
  border-top: 0 !important;

  .nx-table-cell {
    padding: 0 !important;
    border-top: 0 !important;
    vertical-align: top;
  }

  &.nx-header-cell:last-child,
  .nx-table-cell:last-child {
    padding-right: 0 !important;
  }

  &.nx-header-cell:first-child,
  .nx-table-cell:first-child {
    width: 230px;
    padding-left: 0 !important;
    padding-right: 10px !important;
    @media (max-width: 703px) {
      width: 50%;
    }
  }
}

.premium_summary_table{
  border-bottom: 0 !important;
  border-top: 0 !important;
  width: unset !important;

  @media (max-width: 703px) {
    width: 100% !important;
  }


  .nx-table-cell{
    padding: 0 !important;
    border-top: 0  !important;
    vertical-align: top;
  }

  .nx-table-cell:nth-child(2){
    width: 31%;

    @media (max-width: 703px) {
      width: 50%;
    }
  }

  &.nx-header-cell:last-child, .nx-table-cell:last-child{
    padding-right: 0 !important;
  }

  &.nx-header-cell:first-child, .nx-table-cell:first-child{
    width: 240px;
    padding-left: 0 !important;

    @media (max-width: 703px) {
      width: 70%;
    }
  }
}

.coverage_premium_summary_table{
  border-bottom: 0 !important;
  border-top: 0 !important;
  width: unset !important;

  .w-100{
    width: 100% !important;
  }

  @media (max-width: 703px) {
    width: 100% !important;

    .nx-formfield__wrapper {
      padding-bottom: 0 !important;
    }
  }

  @media (min-width: 704px){
    width: 70% !important;
  }

  .percent{
    width: 80px;
  }



  .nx-table-cell{
    padding: 0 !important;
    border-top: 0  !important;
    vertical-align: top;
    height: 32px;
  }

  .nx-table-cell:nth-child(2){
    width: 31%;

    @media (max-width: 703px) {
      width: 25%;
    }
  }


  .nx-table-cell:nth-child(3){
    width: 31%;

    @media (max-width: 703px) {
      width: 25%;
    }
  }

  &.nx-header-cell:last-child, .nx-table-cell:last-child{
    width: 25%;
    padding-right: 0 !important;
  }

  .nx-table-cell:nth-child(2) {
    width: 17%;
  }

  &.nx-header-cell:first-child, .nx-table-cell:first-child{
    width: 240px;
    padding-left: 0 !important;

    // @media (max-width: 703px) {
    //   width: 50%;
    // }
  }

  .nx-formfield__appendix {
    margin-left: 3px !important;
    padding: 0 4px 11px 0px !important;
  }
}

.payment_summary_table{
  border-bottom: 0 !important;
  border-top: 0 !important;
  width: unset !important;

  @media (max-width: 703px) {
    width: 100% !important;
  }


  .nx-table-cell{
    padding: 0 !important;
    border-top: 0  !important;
    vertical-align: top;
  }

  .nx-table-cell:nth-child(2){
    width: 28%;

    @media (max-width: 703px) {
      width: 50%;
    }
  }

  &.nx-header-cell:last-child, .nx-table-cell:last-child{
    padding-right: 0 !important;
  }

  &.nx-header-cell:first-child, .nx-table-cell:first-child{
    width: 280px;
    padding-left: 0 !important;

    @media (max-width: 703px) {
      width: 50%;
    }
  }
}




.summry_grid {
  .nx-grid__column--media-query.nx-grid__column-large-3 {
    flex: 0 0 15% !important;
    max-width: 15% !important;
  }
}

.premium_summary {
  @media (min-width: 704px) {
    &.nx-grid__column--media-query.nx-grid__column-large-5{
      flex: 247px!important;
      max-width: 247px!important;
    }

    &.nx-grid__column--media-query.nx-grid__column-medium-4 {
      flex: 0 0 32.2% !important;
      max-width: 32.2% !important;
    }

    &.nx-grid__column--media-query.nx-grid__column-medium-8 {
      flex: 0 0 33% !important;
      max-width: 33% !important;
    }

    &.nx-grid__column--media-query.nx-grid__column-medium-7 {
      flex: 0 0 30% !important;
      max-width: 30% !important;
    }
  }

  @media (min-width: 1280px) {
    &.nx-grid__column--media-query.nx-grid__column-xlarge-4 {
      flex: 0 0 32.5% !important;
      max-width: 32.5% !important;
    }

    &.nx-grid__column--media-query.nx-grid__column-medium-8 {
      flex: 0 0 27% !important;
      max-width: 27% !important;
    }
    &.nx-grid__column--media-query.nx-grid__column-xlarge-8 {
      flex: 0 0 30% !important;
      max-width: 30% !important;
    }
  }


  //   &.nx-grid__column--media-query.nx-grid__column-2xlarge-8 {
  //       @media (min-width: 704px) {
  //       flex: none !important;
  //       // max-width: max-content !important;
  //       // min-width: 22%;
  //       // flex-basis: 31% !important;
  // //       flex-grow: 1 !important;
  // // flex-basis: 100% !important;
  // width: 31% !important;
  // min-width: 160px !important;
  //   }
}

//   td:first-child {
//     width: 247px;
//     vertical-align: top;

//     @media (max-width: 703px) {
//         width: 50%;
//     }
//   }

//   td:last-child {
//     vertical-align: top;
//     text-align: right;
//     min-width: 160px;
//     display: ruby-text;

//       .nx-copy.nx-copy{
//         float: right;
//       }

//     @media (max-width: 703px) {
//         min-width: 50%;
//     }
//   }

//   td:last-child::before {
//   padding-right: 10px;
//     content: ":";
//     float: left;
//   }


// }

.payment_summary {
  @media (min-width: 704px) {
    &.nx-grid__column--media-query.nx-grid__column-medium-7 {
      flex: 0 0 28% !important;
      max-width: 28% !important;
    }
  }

  @media (min-width: 844px) {
    &.nx-grid__column--media-query.nx-grid__column-medium-7 {
      flex: 0 0 29% !important;
      max-width: 29% !important;
    }
  }

  @media (min-width: 1280px) {
    &.nx-grid__column--media-query.nx-grid__column-xlarge-8 {
      flex: 0 0 29% !important;
      max-width: 29% !important;
    }
  }
}

.checklist {
  &.nx-grid__column--media-query.nx-grid__column-1 {
    flex: 0 0 5% !important;
    max-width: 5% !important;
  }

  &.nx-grid__column--media-query.nx-grid__column-11 {
    flex: 0 0 95% !important;
    max-width: 95% !important;
  }

  &.nx-grid__column--media-query.nx-grid__column-small-2 {
    @media (min-width: 320px) {
      flex: 0 0 8% !important;
      max-width: 8% !important;
    }

    @media (min-width: 704px) {
      flex: 0 0 5% !important;
      max-width: 5% !important;
    }
  }

  &.nx-grid__column--media-query.nx-grid__column-large-1 {
    @media (min-width: 992px) {
      flex: 0 0 5% !important;
      max-width: 5% !important;
    }
  }
}

.checklist_radio {
  &.nx-grid__column--media-query.nx-grid__column-2 {
    flex: 0 0 12% !important;
    max-width: 12% !important;

    @media (min-width: 1472px) {
      flex: 0 0 12% !important;
      max-width: 12% !important;
    }
  }

  &.nx-grid__column--media-query.nx-grid__column-medium-3 {
    @media (min-width: 704px) {
      flex: 0 0 15% !important;
      max-width: 15% !important;
    }

    @media (min-width: 768px) {
      flex: 0 0 19% !important;
      max-width: 19% !important;
    }

    @media (min-width: 932px) {
      flex: 0 0 14% !important;
      max-width: 14% !important;
    }

    @media (min-width: 992px) {
      flex: 0 0 17% !important;
      max-width: 17% !important;
    }
  }
}

.renewal_monthyear {
  &.nx-grid__column--media-query.nx-grid__column-2 {
    padding-right: 0 !important;
    padding-left: 0 !important;
    align-content: center;
    padding-bottom: 21px;
    flex: 0 0 4% !important;
    max-width: 4% !important;
  }

  &.nx-grid__column--media-query.nx-grid__column-5 {
    flex: 0 0 48% !important;
    max-width: 48% !important;
  }
}

/*-----------------------------------------------------------------------------
    $ Accordion
-----------------------------------------------------------------------------*/

.accordion_edit {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.accordion_btm_space {
  &.nx-expansion-panel--light .nx-expansion-panel__body {
    padding: 0px 30px 30px 30px !important;

    @media (max-width: 703px) {
      padding: 0px 0px 30px 0px !important;
    }
  }
}

.accordion_remove_btm_space {
  &.nx-expansion-panel--light .nx-expansion-panel__body {
    padding: 0px 30px 0 30px !important;

    @media (max-width: 703px) {
      padding: 0px 0px 0px 0px !important;
    }
  }
}

//==== Scrollbar ====//

.scrollable {
  padding: 0 !important;
}

/*-----------------------------------------------------------------------------
    $ Table
-----------------------------------------------------------------------------*/

//==== Mobile and Tablet view scrolling ====//
.nx-tab-scroll-indicator__divider,
nx-tab-scroll-indicator.start-button.is-mobile.is-scrolled-to-start,
nx-tab-scroll-indicator.end-button.is-mobile,
nx-tab-scroll-indicator.start-button.is-mobile,
nx-tab-scroll-indicator.end-button.is-desktop-button,
nx-tab-scroll-indicator.start-button.is-desktop-button
{
  display: none !important;
}

//==== Sorting  ====//
// .nx-sort-header__focus-container{
//   flex-direction: row-reverse;
//   place-content: flex-end;
// }

// .nx-sort-header__icons-container{
//   margin-right: 16px !important;
//   justify-content: flex-start !important;
//   margin-left: 0 !important;
// }


// table.nx-table.nx-table--zebra, .nx-table {
//   border-top: 1px solid #d9d9d9;
// }

.content {
  width: 1600px;
  height: 100px;
  padding: 24px;
  background: var(--ui-02);
  display: flex;
  justify-content: space-between;
}

.nx-table-cell {
  font-size: 14px !important;
}

.nx-header-cell {
  font-size: 14px !important;
  line-height: 20px !important;
}

.nx-header-cell:first-child, .nx-table-cell:first-child {
  padding-left: 24px !important;
}

.nx-header-cell:last-child, .nx-table-cell:last-child {
  padding-right: 24px !important;
  white-space: initial;
}


.table_row_flex {
  display: flex !important;
}

.clickable_table tr {
  cursor: pointer;
}

nx-swipebar {
  .nx-copy.nx-copy {
    font-size: 14px;
  }

  .label {
    margin-bottom: 0 !important;
  }
}
//==== Summary Coverage ====//


.table_extra_coverage {
  &.nx-table-cell:first-child,
  &.nx-header-cell:first-child {
    display: block !important;
    width: 450px;

    @media (min-width: 704px) {
      width: 450px;
    }

    @media (min-width: 712px) {
      width: 350px;
    }

    @media (min-width: 720px) {
      width: 410px;
    }

    @media (min-width: 740px) {
      width: 440px;
    }

    @media (min-width: 768px) {
      width: 460px;
    }

    @media (min-width: 800px) {
      width: 400px;
    }

    @media (min-width: 820px) {
      width: 428px;
    }

    @media (min-width: 844px) {
      width: 510px;
    }

    @media (min-width: 853px) {
      width: 430px;
    }

    @media (min-width: 882px) {
      width: 427px;
    }

    @media (min-width: 896px) {
      width: 420px;
    }

    @media (min-width: 912px) {
      width: 532px;
    }

    @media (min-width: 915px) {
      width: 563px;
    }

    @media (min-width: 932px) {
      width: 576px;
    }

    @media (min-width: 1024px) {
      width: 41%;
    }

    @media (min-width: 1114px) {
      width: 42%;
    }

    @media (min-width: 1138px) {
      width: 42%;
    }

    @media (min-width: 1280px) {
      width: 45%;
    }

    @media (min-width: 1366px) {
      width: 44%;
    }

    @media (min-width: 1368px) {
      width: 48%;
    }

    @media (min-width: 1760px) {
      width: 40%;
    }
  }

  &.nx-table-cell:nth-child(2),
  &.nx-header-cell:nth-child(2) {
    display: block !important;
    width: 300px;
    text-align: right;

    @media (min-width: 704px) {
      width: 270px;
    }

    @media (min-width: 712px) {
      width: 250px;
    }

    @media (min-width: 720px) {
      width: 267px;
    }

    @media (min-width: 740px) {
      width: 261px;
    }

    @media (min-width: 768px) {
      width: 276px;
    }

    @media (min-width: 800px) {
      width: 293px;
    }

    @media (min-width: 820px) {
      width: 300px;
    }

    @media (min-width: 844px) {
      width: 320px;
    }

    @media (min-width: 853px) {
      width: 303px;
    }

    @media (min-width: 882px) {
      width: 335px;
    }

    @media (min-width: 896px) {
      width: 380px;
    }

    @media (min-width: 912px) {
      width: 381px;
    }

    @media (min-width: 915px) {
      width: 355px;
    }

    @media (min-width: 932px) {
      width: 362px;
    }

    @media (min-width: 1024px) {
      width: 35.5%;
    }

    @media (min-width: 1114px) {
      width: 35%;
    }

    @media (min-width: 1138px) {
      width: 34.5%;
    }

    @media (min-width: 1280px) {
      width: 33.5%;
    }

    @media (min-width: 1366px) {
      width: 32.5%;
    }

    @media (min-width: 1368px) {
      width: 30%;
    }

    @media (min-width: 1760px) {
      width:37%;
    }
  }

  &.nx-table-cell:nth-child(3),
  &.nx-header-cell:nth-child(3) {
    text-align: right;
    width: 20%;

    @media (min-width: 344px) {
      width: 23.5%;
    }

    @media (min-width: 1760px) {
      width:23%;
    }

  }
}

//==== Display Coverage ====//

.table_extra_coverage_display {
  &.nx-table-cell:first-child,
  &.nx-header-cell:first-child {
    display: block !important;
    width: 450px;

    @media (min-width: 704px) {
      width: 450px;
    }

    @media (min-width: 712px) {
      width: 350px;
    }

    @media (min-width: 720px) {
      width: 410px;
    }

    @media (min-width: 740px) {
      width: 367px;
    }

    @media (min-width: 768px) {
      width: 460px;
    }

    @media (min-width: 800px) {
      width: 400px;
    }

    @media (min-width: 820px) {
      width: 428px;
    }

    @media (min-width: 844px) {
      width: 420px;
    }

    @media (min-width: 853px) {
      width: 430px;
    }

    @media (min-width: 882px) {
      width: 450px;
    }

    @media (min-width: 896px) {
      width: 420px;
    }

    @media (min-width: 912px) {
      width: 533px;
    }

    @media (min-width: 915px) {
      width: 563px;
    }

    @media (min-width: 932px) {
      width: 480px;
    }

    @media (min-width: 1024px) {
      width: 41%;
    }

    @media (min-width: 1114px) {
      width: 45.5%;
    }

    @media (min-width: 1138px) {
      width: 42%;
    }

    @media (min-width: 1280px) {
      width: 40%;
    }

    @media (min-width: 1366px) {
      width: 44%;
    }

    @media (min-width: 1368px) {
      width: 46.5%;
    }

    @media (min-width: 1760px) {
      width: 40%;
    }
  }

  &.nx-table-cell:nth-child(2),
  &.nx-header-cell:nth-child(2) {
    display: block !important;
    width: 300px;
    text-align: right;

    @media (min-width: 704px) {
      width: 270px;
    }

    @media (min-width: 712px) {
      width: 250px;
    }

    @media (min-width: 720px) {
      width: 267px;
    }

    @media (min-width: 740px) {
      width: 261px;
    }

    @media (min-width: 768px) {
      width: 276px;
    }

    @media (min-width: 800px) {
      width: 294px;
    }

    @media (min-width: 820px) {
      width: 300px;
    }

    @media (min-width: 844px) {
      width: 320px;
    }

    @media (min-width: 853px) {
      width: 303px;
    }

    @media (min-width: 882px) {
      width: 335px;
    }

    @media (min-width: 896px) {
      width: 380px;
    }

    @media (min-width: 912px) {
      width: 380px;
    }

    @media (min-width: 915px) {
      width: 355px;
    }

    @media (min-width: 932px) {
      width: 362px;
    }

    @media (min-width: 1024px) {
      width: 35.5%;
    }

    @media (min-width: 1114px) {
      width: 31%;
    }

    @media (min-width: 1138px) {
      width: 36%;
    }

    @media (min-width: 1180px) {
      width: 34.3%;
    }

    @media (min-width: 1280px) {
      width: 36.5%;
    }

    @media (min-width: 1366px) {
      width: 32%;
    }

    @media (min-width: 1368px) {
      width: 30.5%;
    }

    @media (min-width: 1760px) {
      width:36.3%;
    }
  }

  &.nx-table-cell:nth-child(3),
  &.nx-header-cell:nth-child(3) {
    text-align: right;
    width: 23%;

    @media (min-width: 344px) {
      width: 23.5%;
    }

    @media (min-width: 1180px) {
      width: 23.7%;
    }

    @media (min-width: 1366px) {
      width: 24%;
    }

  }
}

//==== Headline & Font Style ====//
h1 {
  font-size: 40px;
  line-height: 48px;
  font-weight: 300;
  margin-top: 30px;
  margin-bottom: 30px;
  @media (max-width: 704px) {
    font-size: 26px;
    font-weight: 400;
    line-height: 30px;
  }

}
h2 {
  font-size: 23px;
  line-height: 32px;
  font-weight: 600;
  @media (max-width: 704px) {
    font-size: 22px;
  }
}
h3 {
  font-size: 26px;
  line-height: 38px;
  font-weight: 300;
}
h4 {
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
}

//===== Link ====//
a {
  color: #003781;
}
a:hover {
  color: #008ed6;
}

// Title Header
.title-header {
  background-image: url("/assets/img/banner_bg.jpg");
  background-repeat: no-repeat;
  // background-attachment: fixed;
  background-size: 100%;
  background-position: left top;

  width: 100%;
  height: 92px;
  // padding: 0px 16px;
  display: flex;
  align-items: center;
  margin-bottom: 45px;

  @media (min-width: 704px) {
    width: 100%;
    height: 108px;
    // padding: 30px 32px;
    display: flex;
    align-items: center;
  }
  @media (min-width: 992px) {
    width: 100%;
    height: 108px;
    // padding: 0px 90px;
    display: flex;
    align-items: center;
  }

  @media (max-width: 992px) {
    margin-bottom: 30px;
  }
}
.my-0 {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}
.mx-0 {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

//==== Position ====//
.float_right {
  float: right;
}

.container_flex_center {
  align-items: center;
  display: flex;
}

.container_flex_start {
  align-items: start;
  display: flex;
}

.container_flex_center.align_right {
  justify-content: right;
}

.container_flex_start.align_right {
  justify-content: right;
}

.container_flex_center.align_left {
  justify-content: left;
}

.container_flex_center.align_center {
  justify-content: center;
}

.align_self_stretch{
  align-self: stretch;
}

/*-----------------------------------------------------------------------------
    $ Footer
-----------------------------------------------------------------------------*/

.footer_container  {
  border-top: 1px solid #d9d9d9 !important;

  @media (min-width: 704px) and (max-width: 992px) {
    /* Styles for viewports between 600px and 1200px wide */
    .footer-medium {
     display: flex;
     justify-content: space-between;
    }
  }
}

.nx-footer {
  color: #767676 !important;
  // padding: 0 32px var(--footer-padding-bottom) 32px !important;
  width: 100%;

  @media (max-width: 703px) {
    padding: 32px 16px var(--footer-padding-bottom) 16px !important;
  }

  @media (min-width: 768px) {
    padding: 32px 16px !important;
  }
}

.nx-footer__navigation {
  @media (max-width: 703px) {
    flex-direction: row !important;
  }
}

.nx-footer__navigation .nx-footer__link {
  @media (max-width: 703px) {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

//==== Input field customize ====//

.formfield_no_padding {
  .nx-formfield__wrapper {
    padding: 0 !important;
  }
}

.dropdown_font_14 {
  .nx-formfield__flexfield {
    font-size: 14px !important;
  }
}

//==== Icon ====//

.rotate_logout_icon {
  transform: rotate(90deg);
}

nx-icon--bars {
  color: #414141 !important;
}

//==== Text Alignment ====//
.std-ta-left {
  text-align: left;
}
.std-ta-center {
  text-align: center;
}
.std-ta-right {
  text-align: right !important;
}
.justify-center {
  text-align-last: center;
}
.std-va-middle {
  vertical-align: middle;
}
.std-va-top {
  vertical-align: top;
}
.std-va-bottom {
  vertical-align: bottom !important;
}

//==== Text Transform ====//
.std-tt-uppercase {
  text-transform: uppercase;
}
.std-tt-cap {
  text-transform: capitalize;
}

//==== Progress Bar ====//

.progress_bar {
  color: #767676;
  text-align: left;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.2;
  width: 200px;
  padding-right: 45px;
  border-right: 1px solid #c2c2c2;


  @media (max-width:993px) {
    width: 100%;
    border-right: none;
    padding-right: 0px;
    padding-bottom: 32px;
    border-bottom: 1px solid #c2c2c2;
  }

  .nx-multi-step-item--vertical .label{
    margin-left: 10px !important;
    white-space: normal !important;
    line-height: 21px !important;
  }

  .nx-multi-step-item--vertical {
    display: inline;
    min-height: 40px;
  }

  .nx-multi-step-item--vertical .line {
    height: 22px !important;
    margin-bottom: 6px;
    margin-top: 4px;
  }

  .nx-multi-step-item--vertical:first-child .line{
    margin-top: -5px;
  }

  .nx-multi-step-item--vertical:nth-child(2) .line{
    margin-top: -5px;
    height: 30px !important;
  }

  // .nx-multi-step-item--vertical:first-child {
  //   // height: 70px;
  // }

  // .nx-multi-step-item--vertical:not(:last-child) {
  //   height: 60px !important;
  //   justify-content: top !important;
  //   display: block;
  // }

  // .nx-multi-step-item--vertical:first-child {
  //   height: 70px !important;
  // }

  .nx-multi-stepper--vertical .nx-multi-stepper__items {
    margin: 0 !important;
  }

  // .nx-multi-step-item--vertical .line {
  //   // margin-top: 7px;
  //   // height: 0 !important;
  // }

  // .nx-multi-step-item--vertical:first-child .line {
  //   // margin-top: -5px;
  // }

  // .nx-multi-step-item--vertical .line {
  //   height: 22px !important;
  //   position: absolute;
  // }

  // .nx-multi-step-item--vertical:first-child .line {
  //   top: 76%;
  // }

  // .nx-multi-step-item--vertical.is-active:first-child .line {
  //   top: 62%;
  // }

  // .nx-multi-step-item--vertical:not(:first-child) .line {
  //   top: 45%;
  // }

  // .nx-multi-step-item--vertical.is-active:not(:first-child) .line {
  //   top: 38%;
  // }

  // .nx-multi-step-item--vertical.is-active .line {
  //   height: 54px !important;
  // }

  // .nx-multi-step-item--vertical:first-child .line {
  //   margin-top: -16px !important;
  // }

  // .nx-multi-step-item--vertical.is-active:first-child .line {
  //   margin-top: -16px !important;
  // }

  // progress bas align large
  // .progress_bar_align {
  // padding: 0px !important;

  // @media (width > 1024px) {
  //   display: flex;
  //   justify-content: end;
  // }
}

//==== Progress Bar for mobile ====//

.steps-layout {
  .step-number {
    width: 60px;
    min-width: 60px;
    border-radius: 50%;
    display: inline-table;
    font-size: 16px;
    text-align: center;
    background-color: #007ab3;
    color: #fff;
    text-transform: lowercase;
    font-weight: 600;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    margin-right: 10px;
  }

  .step-for-mobile {
    display: flex;
    align-items: center;
  }

  li::marker {
    display: none;
    z-index: -5;
    opacity: 0;
    color: transparent;
  }

  .step {
    .step-desc {
      font-size: 24px;
      font-weight: 700;
      line-height: 1.1;
    }
    .next-step {
      font-size: 16px;
      font-weight: 500;
    }
  }

  // @media (min-width: 704px) and (max-width: 994px) {
  //   .step {
  //     .step-desc {
  //       font-size: 24px;
  //       font-weight: 700;
  //     }
  //     .next-step {
  //       font-size: 16px;
  //     }
  //   }
  // }
}



//==== Blue Panel ====//

.blue_panel {
  .nx-expansion-panel__header-content {
    background-color: #007ab3 !important;
    color: #ffffff !important;
    .nx-expansion-panel__header-title {
      width: 100%;
      margin-right: 17px !important;
    }
  }

  .nx-expansion-panel__chevron {
    color: #ffffff !important;
  }

  .gross {
    padding-left: 20px;
    border-left: 1px solid #ffffff;
  }

  .nx-expansion-panel__content {
    background-color: #f5f5f5;
  }

  .agreed_value {
    .nx-formfield__wrapper {
      padding-bottom: 10px !important;
    }
  }

  @media (max-width: 705px) {
    .gross {
      border-left: 0 !important;
      border-top: 2px solid #ffffff;
      width: 100%;
      padding: 20px 0 0 0px !important;
    }
    .gross_trash {
      position: absolute;
      top: -9px;
    }

    .nx-expansion-panel__header-content {
      padding-right: 16px !important;
    }
  }

  @media (max-width: 705px) {
    .contact-details-title {
      font-size: 14px;
    }
  }
}

//==== Coverage ====//

.table_popup {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.button_coverage {
  align-items: center;
  display: flex;
  justify-content: left;

  button {
    margin-bottom: 20px;
  }

  @media (max-width: 704px) {
    display: inline-block;
    button {
      width: 100% !important;
    }
  }
}

@media (max-width: 704px) {
  .button_coverage {
    display: inline-block;
    button {
      width: 100% !important;
    }
  }

  .gross_trash {
    position: absolute;
    top: -9px;
  }
}

.table_dropdown {

  .nx-dropdown-results__option-label,
  .nx-checkbox__label-text {
    font-size: 14px !important;
  }

  .nx-dropdown-results__option-label {
    white-space: nowrap !important;
  }
}

.coverage {
  &.nx-grid.nx-grid--media-query {
    padding-right: 0px !important;
    padding-left: 0px !important;

    @media (max-width: 704px) {
      padding-right: 26px !important;
      padding-left: 16px !important;
    }

    @media (min-width: 705px) {
      .nx-card-inner {
        padding: 40px;
      }
    }
  }



  .test {
    .nx-dropdown-results__option {
      font-size: 14px !important;
    }
  }


  .nx-card {
    width: 100%;
    box-shadow: 0px 0px 3px 0px var(--card-border-color) !important;
    border-color: #d9d9d9 !important;
  }

  .nx-formfield__input-container {
    padding: 0 !important;
  }

  .driver_list {
    .driver_age {
      width: 60px;
      min-width: 60px;
    }

    .driver_id {
      @media (max-width: 992px) {
        width: 150px;
        min-width: 150px;
      }
    }

    .nx-formfield.has-outline .nx-formfield__wrapper {
      padding-bottom: 0px !important;

      .c-input {
        width: max-content !important;
        text-overflow: inherit !important;
      }
    }

    .scrollable {
      padding-top: 0 !important;
    }
  }

  .extra_package,
  .more_extra_cover {
    table-layout: fixed;
    width: 100%;
    border-spacing: 0;

    td {
      font-size: 14px;
      // border:1px solid red;
      // padding: 0px 4px 0px 16px;
      vertical-align: top;
    }

    .sub_cover {
      padding-left: 38px;

      .no_icon li::before {
        content: "" !important;
        display: none !important;
      }

      ul>li {
        padding-left: 10px;

        .nx-list__icon {
          font-size: 12px;
          top: -3px;
        }
      }

      .nx-list--normal li:not(:last-child) {
        margin-bottom: 0;
      }

      .nx-icon--minus {
        width: 1px;
      }

      .nx-icon--minus:before {
        content: "-" !important;
      }
    }

    td.sub_line {
      padding-bottom: 15px;
    }

    .si {
      text-align: right;
    }

    .nx-natural-language-form--small .c-input {
      text-align: right;
    }

    .nx-checkbox__label-text {
      font-size: 14px !important;
    }

    // .nx-checkbox__label-text {
    //   color: #414141 !important;
    // }

    .nx-table-cell,
    .nx-header-cell {
      word-break: keep-all;
      padding: 16px 16px 15px !important;
      white-space: normal !important;
    }

    .nx-formfield__flexfield {
      font-size: 14px !important;
    }

    .nx-natural-language-form__wrapper {
      justify-content: right;
      align-items: center;
    }

    .nx-natural-language-form--small {
      font-size: 14px !important;
      font-weight: 400 !important;
      padding: 0;

      .size-regular {
        width: 55px !important;
        min-width: 55px !important;
      }

      .is-focused .nx-word__inner-wrapper,
      .is-filled .nx-word__inner-wrapper {
        border-bottom: 1px solid #414141 !important;
      }

      .c-input.is-filled {
        color: #414141;
      }

      .c-input.is-filled {
        padding: 0 !important;
      }

      .nx-word__inner-wrapper {
        height: 27px !important;
      }
    }

    @media (min-width: 705px) {

      th:first-child,
      td:first-child {
        width: 45%;
        padding: 0px 4px 0px 16px;
      }

      th:nth-child(2),
      td:nth-child(2) {
        width: 28%;
        text-align: right !important;
        padding: 0px 16px 0px 4px;

      }

      th:nth-child(3),
      td:nth-child(3) {
        width: 17%;
        text-align: right !important;
      }
    }
  }

  @media (max-width: 704px) {
    .extra_package {
      tbody {
        display: grid;
      }
      tr {
        display: contents;
      }
      td {
        display: block;
        padding: 0;
      }
      .si,
      .sub_cover {
        text-align: left;
        padding-left: 37px;
      }
    }

    .more_extra_cover {
      table-layout: auto;
      td:first-child {
        width: 300px;
        min-width: 300px;
        text-align: left;
      }
      td:nth-child(2) {
        width: 250px;
        min-width: 250px;
        text-align: right;
      }
      th:nth-child(3),
      td:nth-child(3) {
        width: 130px;
        min-width: 130px;
        text-align: right;
      }
    }
  }

  .summary {
    .title {
      color: #8d8d8d;
    }
  }
}

.makemodel_button {
  justify-content: flex-start !important;
  border-top: 0 !important;

  button {
    margin-bottom: 40px;
  }

}

// radio button
.radio-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  align-items: center;
  gap: 20px;
  &_label {
    max-width: 650px;
  }

}
.radio-group2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  align-items: center;
  gap: 20px;

  @media (max-width: 703px){
    gap: 10px;
    flex-direction: column;
    align-items: start;
  }
}
.radio-group3 {
  display: flex;

  @media (width < 991px) {
    margin-top: 16px;
  }
}


// custom field make model
.make_model {
  padding-left: 36px;
}
.custom {
  display: flex;
  flex-wrap: wrap;
  gap: 0px;
  flex-direction: column;

  @media (min-width: 704px) {
    gap: 0px;
    flex-direction: column;
  }
  @media (min-width: 992px) {
    // gap: 10px;
    flex-direction: row;
  }
}
.field_label {
  transform: -16px;
  font-size: 12px;
  // line-height: 16px;
  font-weight: 600;
  letter-spacing: 0.5px;
}
.make_container {
  flex: 0 0 auto;
  padding: 2px 0px 0px;
  border-bottom: var(--formfield-border-width) solid var(--formfield-border-color);
  height: 33px;

  @media (max-width: 992px) {
    border-bottom: 0;
    height: inherit;
  }

  span {
    padding-right: 10px;
  }

  .make_label {
    font-size: 20px;
    color: #808080;
  }
}

.input_container {
  flex: 1;
}

.customField {
  .nx-formfield__wrapper {
    padding-top: 1px !important;
  }
}


//==========Enquiry Table===============//

.nx-sort-header__focus-container {
  align-items: flex-start;
}

.nx-sort-header__icons-container {
  margin-top: 3px;
}

.cell_align_right {
  ::ng-deep .nx-sort-header__focus-container {
    display: flex;
    justify-content: end;
    text-align: right;
  }
}

//========== Tab ===============//
// .nx-tab-header {
//   .nx-tab-header__item:before {
//     background-color: var(--tab-item-color) !important;
//     border-bottom: 2px solid var(--tab-item-color) !important;
//   }
// }

// Customer partner

.cp_popup-title {
  font-weight: 300;
  font-size: 30px;
  line-height: 1.2;
}

.cp__customer-detail {
  &-phone {
    display: flex;
    .code {
      width: 120px;
    }
    .info {
      width: 100% !important;
    }
  }
}
.special_vehicle{
  @media (max-width: 704px){
    margin-bottom: 24px;
  }
}



//============== google map search ================//
.search_map_font{
  &.nx-copy.nx-copy{
    @media (min-width:375px) and (max-width:390px){
      font-size: 14px !important;
    }

    @media (min-width:320px) and (max-width:340px){
      font-size: 12px !important;
    }
  }

  &.nx-radio-button--small-label .nx-radio__label{
    @media (min-width:375px) and (max-width:390px){
      font-size: 14px !important;
    }

    @media (min-width:320px) and (max-width:340px){
      font-size: 12px !important;
    }
  }

  &.nx-margin-left-xs {
    @media (min-width:320px) and (max-width:340px){
      margin-left: 8px !important;
    }
  }


  @media (min-width:320px) and (max-width:340px){
    .nx-radio__circle {
      width: 20px !important;
      min-width: 20px !important;
      height: 20px !important;
    }

    &.nx-radio-button--small-label .nx-radio__label--text:not(:empty){
      margin-left: 7px !important;
    }
  }
}


.search-map {
  .pac-card {
    background-color: #fff;
    border: 0;
    border-radius: 2px;
    box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.3);
    margin: 10px;
    padding: 0 0.5em;
    overflow: hidden;
    padding: 0;
    position: absolute;

    @media (max-width: 704px) {
      width: 96%;
      margin: 0;
    }
  }

  #pac-container {
    width: 100%;
    padding: 12px !important;
    max-width: 370px;
    // padding-right: 12px ;
  }

  .pac-controls {
    display: inline-block;
    padding: 5px 11px;

    @media (max-width: 704px) {
      .radio-group {
        display: flex;
        gap: 8px;
      }

      .nx-radio-button--small-label[_nghost-ng-c3224567837] .nx-radio__label--text[_ngcontent-ng-c3224567837]:not(:empty) ::ng-deep .nx-radio__label {
        margin-left: 8px !important;
      }
      
    }
  }

  .pac-controls label {
    font-size: 13px;
    font-weight: 300;
  }

  #pac-input {
    background-color: #fff;
    // font-family: Roboto;
    font-size: 15px;
    font-weight: 300;
    // margin-left: 12px;
    padding: 0 11px 0 13px;
    text-overflow: ellipsis;
    width: 100%;
  }

  #pac-input:focus {
    border-color: #4d90fe;
  }

  #title {
    color: #fff;
    background-color: #4d90fe;
    font-size: 25px;
    font-weight: 500;
    padding: 8px 12px;
  }

  .radio-map {
    font-size: 14px;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 10px;
  }

  .lat_long {
    font-size: 14px;
    display: flex;
    // justify-content: start;
    // align-items: center;
    gap: 20px;
    width: 100%;

    @media (max-width: 703px){
      flex-direction: column;
    }
  }

  .map_size {
    width: 100%;
  }
}

.map {
  width: 100% !important;
  height: 450px !important;
}
// Premium Approval

.premium_approval {
  .nx-formfield__input-container {
    flex: inherit !important;
    height: inherit !important;
  }

  .c-input {
    font-size: 16px !important;
    font-weight: 400 !important;
    text-align: right !important;
  }

  @media (max-width: 705px) {
    .nx-grid--no-gutters
      .nx-grid__row
      > [class*="nx-grid__column"]:not(:first-child) {
      padding: 0px 10px !important;
    }
  }
}

// Header //

// .nx-header__row {
//   @media (max-width: 704px) {
//     padding: 0 24px;
//   }

//   @media (min-width: 992px) {
//     padding: 1px 80px 0 !important;
//   }
// }

.menu_list {
  .nx-dropdown-results__option-label {
    font-size: 16px !important;
  }
}


.border-line {
  border-right: 1px solid #d9d9d9;
  height: 30px;
}


.arrow_icon {
  width: 12px;
  height: 12px;
  background-color: inherit;
  position: absolute;
  transform: translate(-50%);
  border-top: 1px solid var(--popover-border-color);
  border-left: 1px solid var(--popover-border-color);
  left: 49%;
  rotate: 45deg;
  top: -2px;
}

// #nx-dropdown-modal-1 {
//   margin-top:40px;
//   box-shadow: 0 8px 24px rgba(65, 65, 65, 0.35);
//   border: 1px solid #d9d9d9;
//   overflow-y: inherit;
//   position: absolute;
//   padding-top: 16px;
// }

.top_header_menu {

  .nx-formfield.is-focused:not(.has-outline) .nx-formfield__input-container:after {
    background-color: transparent !important;
  }

  nx-dropdown {
    font-size: 14px;
  }

  .nx-icon--chevron-down {
    margin-left: 5px;
    font-size: 18px !important;
  }

  .nx-formfield__flexfield {
    font-size: 16px !important;
  }

  .nx-formfield__wrapper {
    padding: 0 !important;
  }

  .nx-formfield__input-container {
    border: 0 !important;
    outline: none !important;
  }

  .nx-formfield.is-focused,
  .nx-formfield.is-filled,
  .nx-formfield.has-focus,
  .nx-formfield.is-auto-floating {
    border: 0 !important;
    color: #414141 !important;
    outline: none !important;
  }
}



.bottom_header_menu {
  .nx-menu-button.policytab {
    padding: 0;
  }

  .nx-menu-button {
    font-size: 16px;
    color: #414141;
    font-weight: 400;
    background-color: transparent;

    .nx-icon--chevron-down {
      display: none;
    }
  }

  hr {
    margin: 0 !important;
    border-top: 1px solid #d9d9d9 !important;
  }

  .nx-icon--chevron-right {
    font-size: 16px !important;
  }

  .nx-menu-button:hover {
    font-weight: 400;
    background-color: transparent;
  }

  .nx-menu-button.is-expanded {
    font-weight: 700;
    background-color: transparent;
    color: var(--header-link-active-color);

    .nx-header__link.policy {
      border-bottom-style: solid;
      border-color: var(--header-link-active-color);
    }
  }

  .nx-menu-button__label {
    padding: 0 !important;
    line-height: inherit !important;
  }

  .sub_menu {
    position: absolute;
    z-index: 2;
    background-color: #f5f5f5;
    left: 0;
    width: 100%;
    line-height: 30px;
    margin-top: 10px;
    padding: 20px 32px 0 32px;
    // max-height: 70vh;
    // overflow-y: auto;
    // -webkit-overflow-scrolling: touch;
    a {
      cursor: pointer;
    }
  }

  .nx-menu__link.nested_sub_menu {
    display: block;
    padding-left: 40px;
    line-height: 22px;

    .nx-menu-button__icon {
      margin-right: 5px !important;
    }
  }

  .header_nested_sub_menu {
    margin-bottom: 4px;

    .nx-icon--chevron-right {
      margin-right: 4px;
    }

  }
  .header_nested_sub_menu.is-expanded {
    font-weight: 700 !important;
    color:#414141 !important;

    .nx-icon--chevron-right {
      transform: rotate(90deg);
    }
  }


  // tablet/ mobile
  @media (max-width: 704px) {
    .nx-header__link {
      padding: 20px;
    }

    .nx-header__link.policytab {
      padding: 0 16px 0 0;
    }

    .nx-header__actions {
      margin-left: 4px !important;
    }

    .nx-header__brand {
      display: inline-block;
    }


  }

  @media (min-width: 704px) and (max-width: 992px) {
    .nx-header__link {
      padding: 20px 32px;
    }

    .nx-header__link.policy {
      padding: 0;
    }

    .nx-menu-button.policytab {
      padding: 20px 32px;
    }
  }

  @media (max-width: 992px) {
    .nx-header__navigation-items {
      display: inline-block !important;
      background-color: #ffffff;
      top: 60px;
      z-index: 2;
      position: absolute;
      width: 100%;
      left: 0;
      box-shadow: 0 2px 4px #0003;
    }

    .is-expanded .nx-menu-button__expand-icon {
      color: #ffffff;
    }

    .sub_menu {
      position: relative;
      background-color: #ffffff;
      padding: 0;
    }

    .header_nested_sub_menu {
      .nx-icon--chevron-down {
        display: none !important;
      }
    }

    .header_nested_sub_menu.is-expanded {
      background-color: transparent !important;
      color: #414141 !important;
      .nx-icon--chevron-down {
        display: none !important;
      }
    }

    .nx-header__link:hover {
      border-color: transparent;
    }

    .nx-header__link:active {
      border-color: transparent;
    }

    .nx-menu-button.is-expanded {
      background-color: #007ab3;


      .nx-header__link.policy {
        border-bottom-style: none;
        border-color: transparent;
        color: #fff;
      }
    }

    .nx-menu-button {
      .nx-icon--chevron-down {
        display: block;
      }
    }
  }



  .tab__content {
    padding-top: 16px;
  }

  nx-tab-header {
    justify-content: flex-start !important;
  }


}

.nx-icon--bars {
  font-size: 40px !important;
}
/*-----------------------------------------------------------------------------
    $ Button
-----------------------------------------------------------------------------*/
.nx-button--primary, .nx-button--secondary {
  font-size: 14px !important;
}

/*-----------------------------------------------------------------------------
    $ Button scroll to top
-----------------------------------------------------------------------------*/
.to-top {
  bottom: 0;
  cursor: pointer;
  margin: 0 8px 12px 0;
  position: fixed;
  right: 0;

  img {
    object-fit: contain;
    width: 30px;
    height: 35px;
    object-fit: contain;
    cursor: pointer;
    opacity: 1;
  }
}

.show-scrollTop {
  opacity: 1;
  transition: all 0.2s ease-in-out;
}

/*-----------------------------------------------------------------------------
    $ Remove border accordion
-----------------------------------------------------------------------------*/
@media (max-width: 704px) {
  .nx-expansion-panel--light:not(.nx-expansion-panel--negative):only-child {
    box-shadow: none !important;
  }

  .nx-expansion-panel--light:not(.nx-expansion-panel--negative):last-child {
    box-shadow: none !important;
    // border-bottom: 1px solid #d9d9d9;
  }


  .nx-expansion-panel--light:not(.nx-expansion-panel--negative) .nx-expansion-panel__header-content {
    box-shadow: none !important;
    border-top: 1px solid #d9d9d9;
  }
}

/*-----------------------------------------------------------------------------
    $ Remove border accordion
-----------------------------------------------------------------------------*/

.cross_sell_banner {

  background-color: #E6F4F6;
  padding: 30px 120px;

  @media (min-width: 704px) and (max-width: 994px) {
    padding: 30px 0px;
  }

  @media (max-width: 993px) {
    padding: 30px 16px;

    .nx-grid__row--media-query {
      flex-wrap: unset !important;
    }
  }

  img {
    display: block;
    width: 100%;

    object-position: center;
    object-fit: cover;
    max-width: 100%;

    @media (max-width: 992px) {
      max-height: 220px;
      // height: 220px;
    }
  }

  @media (max-width: 992px)  {
    .scroll {
      overflow-y: auto;
    }

    .scroll {
      -ms-overflow-style: none;  /* Internet Explorer 10+ */
      scrollbar-width: none;  /* Firefox */
    }
    .scroll::-webkit-scrollbar {
      display: none;  /* Safari and Chrome */
    }

    .nx-grid--no-padding.nx-grid--media-query {
      padding-right: 16px !important;
      padding-left: 16px !important;
    }

  }

  .title {
    margin: 0 auto;
    max-width: 880px;
    margin-bottom: 30px;
  }

  .orange_text {
    color: #FF934F;
  }

  .banner_pagination {
    justify-content: center;
    display:flex;
  }

  .normal_text {
    font-size: 16px;

    @media (max-width: 704px) {
      font-size: 12px;
    }
  }

  .buttons {
    @media (max-width:992px) {
      .nx-grid__column--media-query.nx-grid__column {
        padding: 20px 0px 0px 0px !important;
      }
    }
    @media (max-width: 703px) {
      display: block !important;
      padding: 0px !important;
    }
  }

  .buttons_container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 705px) {
      display: inline-block;
    }

    .nx-grid__column--media-query.nx-grid__column {
      padding: 0 !important;
    }
  }

  @media (max-width: 992px) {
    .content_text {
      padding: 10px 0;
    }

    .banner_color {
      background-color: white;
    }

    .banner_img {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

  }
}


// =================== table scroll bar ===================

//====================Dashboard========================== //
.table_dashboard {
  .wmd-view-topscroll,
  .wmd-view {
    overflow-x: scroll;
    // overflow-y: hidden;
    width: 100%;
    // border: none 0px RED;
    height: auto;
  }
  .wmd-view-topscroll {
    height: auto;
  }

  .wmd-view {
    height: auto;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  .wmd-view::-webkit-scrollbar {
    display: none;  /* Chrome, Safari, and Opera */
  }
  .scrollbar-wrapper{
    height: 10px;
    overflow: hidden;
    width: 100%;
  }
  .scroll-div1 {
    width: 1440px;
    height: 0.1px !important;
  }
  .scroll-div2 {
    width: 1440px;
    height: auto !important;
  }

  ::-webkit-scrollbar {
    width: 10px !important;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0px #d9d9d9;
    background-color: #d9d9d9;
    // border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #007ab3;
    // border-radius: 10px;
    cursor: grab;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #007ab3;
  }

  .hide-scrollbar {
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
    overflow: -moz-scrollbars-none; /* For older versions of Firefox */
  }

  .hidden{
    display: none;
  }
}

//====================Enquiry========================== //
.table_enquiry {
  .wmd-view-topscroll,
  .wmd-view {
    overflow-x: scroll;
    // overflow-y: hidden;
    width: 100%;
    // border: none 0px RED;
    height: auto;
  }
  .wmd-view-topscroll {
    height: auto;
  }

  .wmd-view {
    height: auto;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  .wmd-view::-webkit-scrollbar {
    display: none;  /* Chrome, Safari, and Opera */
  }
  .scrollbar-wrapper{
    height: 10px;
    overflow: hidden;
    width: 100%;
  }
  .scroll-div1 {
    width: 1056px;
    height: 0.1px !important;
  }
  .scroll-div2 {
    width: 1056px;
    height: auto !important;
  }

  ::-webkit-scrollbar {
    width: 10px !important;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0px #d9d9d9;
    background-color: #d9d9d9;
    // border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #007ab3;
    // border-radius: 10px;
    cursor: grab;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #007ab3;
  }

  .hide-scrollbar {
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
    overflow: -moz-scrollbars-none; /* For older versions of Firefox */
  }

  .hidden{
    display: none;
  }
}

//====================Enquiry========================== //
.table_summary {
  .wmd-view-topscroll,
  .wmd-view {
    overflow-x: scroll;
    // overflow-y: hidden;
    width: 100%;
    // border: none 0px RED;
    height: auto;
  }
  .wmd-view-topscroll {
    height: auto;
  }

  .wmd-view {
    height: auto;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  .wmd-view::-webkit-scrollbar {
    display: none;  /* Chrome, Safari, and Opera */
  }
  .scrollbar-wrapper{
    height: 10px;
    overflow: hidden;
    width: 100%;
  }
  .scroll-div1 {
    width: 815px;
    height: 0.1px !important;
  }
  .scroll-div2 {
    width: 815px;
    height: auto !important;
  }

  ::-webkit-scrollbar {
    width: 10px !important;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0px #d9d9d9;
    background-color: #d9d9d9;
    // border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #007ab3;
    // border-radius: 10px;
    cursor: grab;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #007ab3;
  }

  .hide-scrollbar {
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
    overflow: -moz-scrollbars-none; /* For older versions of Firefox */
  }

  .hidden{
    display: none;
  }
}



// scroll tab;e follow equote
.only-this-horizon-scrollbar::-webkit-scrollbar{
  height: 8px;
  width: 6px;
  background: #dedede;
}

/* Track */
.only-this-horizon-scrollbar::-webkit-scrollbar-track {
  background: #dedede;
}

/* Handle */
.only-this-horizon-scrollbar::-webkit-scrollbar-thumb {
  background: #dedede;
}

/* Handle on hover */
.only-this-horizon-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #dedede;
}

.only-this-horizon-scrollbar::-webkit-scrollbar-thumb:horizontal{
  background: #007ab3;
}

.table_content_scroll{
  max-width: 100%;
  // overflow-x: scroll;
  overflow-y: hidden;
  display: block;
  // max-width: -moz-fit-content;
  // max-width: fit-content;
  //   margin: 0 auto;
  overflow-x: auto;
  //   white-space: nowrap;
}


// ======================== Radio Button ==========================
.nx-radio-button--big-label .nx-radio__label{
  font-size: 17px !important;

  @media (max-width: 991px){
    font-size: 20px !important;
  }
}


// .nx-formfield.is-focused:not(.has-outline) .nx-formfield__input-container:after {
//   background-color: transparent !important;
//  }


.nx-dropdown-item--active .nx-dropdown-results__option .nx-dropdown-results__option-label    {
  box-shadow: none !important;
}

// for box shadow in complete page
nx-card.nx-card.completed_card{
  border-style: none;
  box-shadow: 0 0 10px -5px #000 !important;
}

.location_icon {
  button.nx-plain-button {
    line-height: 4px;
  }

  .location{
    font-size: 17px !important;
    position: relative;
    bottom: 2px;
  }
}

.nx-margin-top-30 {
  margin-top: 30px !important;
}

.cp_not_complete {
  border-bottom: 0 !important;
  border-top: 0 !important;

  .nx-table-cell {
    padding: 0 !important;
    border-top: 0 !important;
    vertical-align: top;
  }

  &.nx-header-cell:last-child,
  .nx-table-cell:last-child {
    padding-right: 0 !important;
  }

  &.nx-header-cell:first-child,
  .nx-table-cell:first-child {
    width: 230px;
    padding-left: 0 !important;

    @media (max-width: 703px) {
      width: 50%;
    }
  }
}