.nx-margin-3xs {
  margin: 4px !important;
}

.nx-margin-x-3xs {
  margin-right: 4px !important;
  margin-left: 4px !important;
}

.nx-margin-y-3xs {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}

.nx-margin-top-3xs {
  margin-top: 4px !important;
}

.nx-margin-bottom-3xs {
  margin-bottom: 4px !important;
}

.nx-margin-left-3xs {
  margin-left: 4px !important;
}

.nx-margin-right-3xs {
  margin-right: 4px !important;
}

.nx-margin-2xs {
  margin: 8px !important;
}

.nx-margin-x-2xs {
  margin-right: 8px !important;
  margin-left: 8px !important;
}

.nx-margin-y-2xs {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.nx-margin-top-2xs {
  margin-top: 8px !important;
}

.nx-margin-bottom-2xs {
  margin-bottom: 8px !important;
}

.nx-margin-left-2xs {
  margin-left: 8px !important;
}

.nx-margin-right-2xs {
  margin-right: 8px !important;
}

.nx-margin-xs {
  margin: 12px !important;
}

.nx-margin-x-xs {
  margin-right: 12px !important;
  margin-left: 12px !important;
}

.nx-margin-y-xs {
  margin-top: 12px !important;
  margin-bottom: 12px !important;
}

.nx-margin-top-xs {
  margin-top: 12px !important;
}

.nx-margin-bottom-xs {
  margin-bottom: 12px !important;
}

.nx-margin-left-xs {
  margin-left: 12px !important;
}

.nx-margin-right-xs {
  margin-right: 12px !important;
}

.nx-margin-s {
  margin: 16px !important;
}

.nx-margin-x-s {
  margin-right: 16px !important;
  margin-left: 16px !important;
}

.nx-margin-y-s {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}

.nx-margin-top-s {
  margin-top: 16px !important;
}

.nx-margin-bottom-s {
  margin-bottom: 16px !important;
}

.nx-margin-left-s {
  margin-left: 16px !important;
}

.nx-margin-right-s {
  margin-right: 16px !important;
}

.nx-margin-m {
  margin: 24px !important;
}

.nx-margin-x-m {
  margin-right: 24px !important;
  margin-left: 24px !important;
}

.nx-margin-y-m {
  margin-top: 24px !important;
  margin-bottom: 24px !important;
}

.nx-margin-top-m {
  margin-top: 24px !important;
}

.nx-margin-bottom-m {
  margin-bottom: 24px !important;
}

.nx-margin-left-m {
  margin-left: 24px !important;
}

.nx-margin-right-m {
  margin-right: 24px !important;
}

.nx-margin-2m {
  margin: 32px !important;
}

.nx-margin-x-2m {
  margin-right: 32px !important;
  margin-left: 32px !important;
}

.nx-margin-y-2m {
  margin-top: 32px !important;
  margin-bottom: 32px !important;
}

.nx-margin-top-2m {
  margin-top: 32px !important;
}

.nx-margin-bottom-2m {
  margin-bottom: 32px !important;
}

.nx-margin-left-2m {
  margin-left: 32px !important;
}

.nx-margin-right-2m {
  margin-right: 32px !important;
}

.nx-margin-3m {
  margin: 40px !important;
}

.nx-margin-x-3m {
  margin-right: 40px !important;
  margin-left: 40px !important;
}

.nx-margin-y-3m {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.nx-margin-top-3m {
  margin-top: 40px !important;
}

.nx-margin-bottom-3m {
  margin-bottom: 40px !important;
}

.nx-margin-left-3m {
  margin-left: 40px !important;
}

.nx-margin-right-3m {
  margin-right: 40px !important;
}

.nx-margin-4m {
  margin: 48px !important;
}

.nx-margin-x-4m {
  margin-right: 48px !important;
  margin-left: 48px !important;
}

.nx-margin-y-4m {
  margin-top: 48px !important;
  margin-bottom: 48px !important;
}

.nx-margin-top-4m {
  margin-top: 48px !important;
}

.nx-margin-bottom-4m {
  margin-bottom: 48px !important;
}

.nx-margin-left-4m {
  margin-left: 48px !important;
}

.nx-margin-right-4m {
  margin-right: 48px !important;
}

.nx-margin-l {
  margin: 56px !important;
}

.nx-margin-x-l {
  margin-right: 56px !important;
  margin-left: 56px !important;
}

.nx-margin-y-l {
  margin-top: 56px !important;
  margin-bottom: 56px !important;
}

.nx-margin-top-l {
  margin-top: 56px !important;
}

.nx-margin-bottom-l {
  margin-bottom: 56px !important;
}

.nx-margin-left-l {
  margin-left: 56px !important;
}

.nx-margin-right-l {
  margin-right: 56px !important;
}

.nx-margin-xl {
  margin: 64px !important;
}

.nx-margin-x-xl {
  margin-right: 64px !important;
  margin-left: 64px !important;
}

.nx-margin-y-xl {
  margin-top: 64px !important;
  margin-bottom: 64px !important;
}

.nx-margin-top-xl {
  margin-top: 64px !important;
}

.nx-margin-bottom-xl {
  margin-bottom: 64px !important;
}

.nx-margin-left-xl {
  margin-left: 64px !important;
}

.nx-margin-right-xl {
  margin-right: 64px !important;
}

.nx-margin-2xl {
  margin: 72px !important;
}

.nx-margin-x-2xl {
  margin-right: 72px !important;
  margin-left: 72px !important;
}

.nx-margin-y-2xl {
  margin-top: 72px !important;
  margin-bottom: 72px !important;
}

.nx-margin-top-2xl {
  margin-top: 72px !important;
}

.nx-margin-bottom-2xl {
  margin-bottom: 72px !important;
}

.nx-margin-left-2xl {
  margin-left: 72px !important;
}

.nx-margin-right-2xl {
  margin-right: 72px !important;
}

.nx-margin-3xl {
  margin: 80px !important;
}

.nx-margin-x-3xl {
  margin-right: 80px !important;
  margin-left: 80px !important;
}

.nx-margin-y-3xl {
  margin-top: 80px !important;
  margin-bottom: 80px !important;
}

.nx-margin-top-3xl {
  margin-top: 80px !important;
}

.nx-margin-bottom-3xl {
  margin-bottom: 80px !important;
}

.nx-margin-left-3xl {
  margin-left: 80px !important;
}

.nx-margin-right-3xl {
  margin-right: 80px !important;
}

.nx-margin-4xl {
  margin: 88px !important;
}

.nx-margin-x-4xl {
  margin-right: 88px !important;
  margin-left: 88px !important;
}

.nx-margin-y-4xl {
  margin-top: 88px !important;
  margin-bottom: 88px !important;
}

.nx-margin-top-4xl {
  margin-top: 88px !important;
}

.nx-margin-bottom-4xl {
  margin-bottom: 88px !important;
}

.nx-margin-left-4xl {
  margin-left: 88px !important;
}

.nx-margin-right-4xl {
  margin-right: 88px !important;
}

.nx-margin-5xl {
  margin: 96px !important;
}

.nx-margin-x-5xl {
  margin-right: 96px !important;
  margin-left: 96px !important;
}

.nx-margin-y-5xl {
  margin-top: 96px !important;
  margin-bottom: 96px !important;
}

.nx-margin-top-5xl {
  margin-top: 96px !important;
}

.nx-margin-bottom-5xl {
  margin-bottom: 96px !important;
}

.nx-margin-left-5xl {
  margin-left: 96px !important;
}

.nx-margin-right-5xl {
  margin-right: 96px !important;
}

.nx-margin-top-0 {
  margin-top: 0 !important;
}

.nx-margin-bottom-0 {
  margin-bottom: 0 !important;
}

.nx-margin-left-0 {
  margin-left: 0 !important;
}

.nx-margin-right-0 {
  margin-right: 0 !important;
}

.nx-margin-x-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.nx-margin-y-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.nx-margin-0 {
  margin: 0 !important;
}

.nx-font-weight-light {
  font-weight: 300 !important;
}

.nx-font-weight-regular {
  font-weight: 400 !important;
}

.nx-font-weight-semibold {
  font-weight: 600 !important;
}

.nx-font-weight-bold {
  font-weight: 700 !important;
}

@media (max-width: 319px) {
  .nx-hidden-xs {
    display: none !important;
  }
}
@media (min-width: 320px) and (max-width: 703px) {
  .nx-hidden-s {
    display: none !important;
  }
}
@media (min-width: 704px) and (max-width: 991px) {
  .nx-hidden-m {
    display: none !important;
  }
}
@media (min-width: 992px) and (max-width: 1279px) {
  .nx-hidden-l {
    display: none !important;
  }
}
@media (min-width: 1280px) and (max-width: 1471px) {
  .nx-hidden-xl {
    display: none !important;
  }
}
@media (min-width: 1472px) and (max-width: 1759px) {
  .nx-hidden-2xl {
    display: none !important;
  }
}
@media (min-width: 1760px) {
  .nx-hidden-3xl {
    display: none !important;
  }
}
.nx-modal-margin-bottom {
  margin-bottom: 40px !important;
}

@media (max-width: 703px) {
  .nx-modal-margin-bottom {
    margin-bottom: 32px !important;
  }
}
